// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.biochoice.ng/api',
  // API_URL: 'http://localhost:8030/api',
  // API_URL: 'http://197.255.63.148:8030/api',
  // SOCKET_URL: 'http://localhost:8030',
  SOCKET_URL: 'http://197.255.63.148:8030',
  // SOCKET_URL: 'https://biochoice.ng/',
  API_VERSION: 'v1',
  JWT_KEY: '',
  DATABASE_HEADER: 'ojectmanagerplk900098765rfghgfdertyuhghgfrtyhbdrtyuytfhtrtyuhgghtrdfgytdghtrtre2345678uyfdvcxsaqq5OLALEKAN_AROKOYUsUPPER123FG[POIUHJFGHGFR6YT45678IUHGFGYYdfghuytr6yhgfLink08j-987to890pojrt9-2tredsdfguytr08j-987to890pojrt9',
  TOKEN: sessionStorage.getItem('access'),
  PRIVATE_KEY: `3456JhgfjklLKJHG@pr!@oject#manager][][plk900098765rfghgfdertyuhghgfrtyhbdrtyuytfhtrtyuhgghtrdfgytdghtrtre2345678uyfdvcxsaqq5wertyuijbvcxdffdcvgfghyt6t`,
  USERTOKENS: '',
  ACTION_KEY: '12384-09875poiuyty-987to890pojrt9-2976tred;sdfguytr08j-987to890pojrt9-2tred;sdfguytr08j-987to890pojrt9-2tred;sdfguytr08j-arokoyuolalekan',
  URL_VALIDATION:  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  EMAIL_VALIDATION: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
/*
<script>
// Initialize Firebase
var config = {
  apiKey: "AIzaSyD-CF4wEBnwfPMEraUWWzN3lqW6eacnMno",
  authDomain: "biochoice-6fd43.firebaseapp.com",
  databaseURL: "https://biochoice-6fd43.firebaseio.com",
  projectId: "biochoice-6fd43",
  storageBucket: "",
  messagingSenderId: "838365910462"
};
firebase.initializeApp(config);
</script>*/
