/**
 * Created by Arokoyu Olalekan Ojo
 */

import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {Observable} from 'rxjs/Observable';
import {ApiService} from '../api/api.service.';
import {isNullOrUndefined} from 'util';
import { environment as env } from '../../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';

@Injectable()
export class UserService {

  constructor(private api: ApiService) {

  }


  /**
   * Api used to sign up a user
   * @param {User} user
   * @returns {Observable<IResponse>}
   */

  userSignUp(user: User): Observable<IResponse> {
    return this.api.postRequest('auth', 'signup', user).map((res: IResponse) => {
      return res ;
    });
  }


  /**
   * Login the user
   * @param credentials
   * @returns {Observable<IResponse>}
   */
  auth(credentials): Observable<IResponse> {
    return this.api.postRequest('auth', 'login' , credentials).map((res: IResponse)  => {
      return res ;
    });
  }
  supeAuth(credentials): Observable<IResponse> {
    return this.api.postRequest('auth', 'super-login?biochoice_access_control=' + env.DATABASE_HEADER , credentials).map((res: IResponse)  => {
      return res ;
    });
  }
  getCompanies(): Observable<IResponse> {
    return this.api.getRequest('auth', 'companies/pre-login/access-generator?biochoice_access_control=' + env.DATABASE_HEADER).map((res: IResponse)  => {
      return res ;
    });
  }
  createCompany(data: any): Observable<IResponse> {
    return this.api.postRequest('tenancy', 'super-admin/process?biochoice_access_control=' + env.DATABASE_HEADER , data).map((res: IResponse)  => {
      return res ;
    });
  }
  createDevice(data: any): Observable<IResponse> {
    return this.api.postRequest('tenancy', 'super-admin/process/devices?biochoice_access_control=' + env.DATABASE_HEADER , data).map((res: IResponse)  => {
      return res ;
    });
  }
  userListCompanies(): Observable<IResponse> {
    return this.api.getRequest('tenancy', 'super-admin/process?biochoice_access_control=' + env.DATABASE_HEADER ).map((res: IResponse)  => {
      return res ;
    });
  }
  adminListDevices(): Observable<IResponse> {
    return this.api.getRequest('tenancy', 'super-admin/process/devices?biochoice_access_control=' + env.DATABASE_HEADER ).map((res: IResponse)  => {
      return res ;
    });
  }
  listDevices(): Observable<IResponse> {
    return this.api.getRequest('devices', null).map((res: IResponse)  => {
      return res ;
    });
  }

  logOut(): Observable<IResponse> {
    return this.api.deleteRequest('auth', 'logout').map((res: IResponse)  => {
      return res ;
    });
  }

  getUsers(): Observable<IResponse> {
    return this.api.getRequest('users', null).map((res: IResponse)  => {
      return res ;
    });

  }

  getLogCount(): Observable<IResponse> {
    return this.api.getRequest('logs', 'count').map((res: IResponse)  => {
      return res ;
    });

  }

  getUserCount(): Observable<IResponse> {
    return this.api.getRequest('users', 'count').map((res: IResponse)  => {
      return res ;
    });

  }


  getGuestCount(): Observable<IResponse> {
    return this.api.getRequest('guest', 'count').map((res: IResponse)  => {
      return res ;
    });

  }

  getGuests(): Observable<IResponse> {
    return this.api.getRequest('guest', null).map((res: IResponse)  => {
      return res ;
    });

  }
  exportToCSV(target): Observable<IResponse> {
    switch (target) {
      case 'staffs': {
        return this.api.getRequest('users', 'export').map((res: IResponse)  => {
          return res ;
        });
      }
      default: {
        break;
      }
    }

  }
  getTimeLogs(): Observable<IResponse> {
    return this.api.getRequest('report', null).map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilter(filter): Observable<IResponse> {
    return this.api.postRequest('report', 'filter', filter).map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterSingle(filter): Observable<IResponse> {
    return this.api.postRequest('report', 'filter', filter).map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterFew(): Observable<IResponse> {
    return this.api.getRequest('report', 'today/few').map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogs(): Observable<IResponse> {
    return this.api.getRequest('report', 'all-first-logs').map((res: IResponse)  => {
      return res ;
    });
  }
  getReport(): Observable<IResponse> {
    return this.api.getRequest('report', null).map((res: IResponse)  => {
      return res ;
    });
  }
  postReport(): Observable<IResponse> {
    return this.api.postRequest('report', null, null).map((res: IResponse)  => {
      return res ;
    });
  }
  getLogComment(id: string): Observable<IResponse> {
    return this.api.getRequest('comment', 'log/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  createComment(data): Observable<IResponse> {
    return this.api.postRequest('comment', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogsForMonth(data): Observable<IResponse> {
    return this.api.postRequest('report', 'first-logs-for-month', data).map((res: IResponse)  => {
      return res ;
    });
  }
  logsForYear(data): Observable<IResponse> {
    return this.api.postRequest('report', 'logs-for-year', data).map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogsForDayInMonth(data): Observable<IResponse> {
    return this.api.postRequest('report', 'first-logs-for-day-in-month', data).map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogsForMonthInYear(data): Observable<IResponse> {
    return this.api.postRequest('report', 'first-logs-for-month-in-year', data).map((res: IResponse)  => {
      return res ;
    });
  }
  freeLogs(): Observable<IResponse> {
    return this.api.getRequest('report', 'free').map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterByRange(startDate, endDate): Observable<IResponse> {
    return this.api.getRequest('report', `filter/range?startDate=${startDate}&endDate=${endDate}`).map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilterWithUser(date, staffId): Observable<IResponse> {
    return this.api.getRequest('report', `staff/${staffId}/filter/range?date=${date}`).map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilterByRangeWithUser(startDate, endDate, staffId): Observable<IResponse> {
    return this.api.getRequest('report', `staff/${staffId}/filter/range?startDate=${startDate}&endDate=${endDate}`).map((res: IResponse)  => {
      return res ;
    });

  }
  deleteStaff(id): Observable<IResponse> {
    return this.api.deleteRequest('users', id).map((res: IResponse)  => {
      return res ;
    });

  }
  deleteLog(id): Observable<IResponse> {
    return this.api.deleteRequest('report', id).map((res: IResponse)  => {
      return res ;
    });

  }
  createOrUpdateStaff(data, isUpdateProcess): Observable<IResponse> {
    if (!isUpdateProcess) {
      return this.api.postRequest('users', null,  data ).map((res: IResponse) => {
        return res;
      });
    } else {
      return this.api.putRequest('users', data._id, data).map((res: IResponse) => {
        return res;
      });
    }

  }

}
