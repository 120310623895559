import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as moment from 'moment';
declare const $: any;

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit {
public guestList: any = [];
  constructor(private userService: UserService,
              private notifyService: NotificationService) { }

  ngOnInit() {
    this.getGuests();
  }
  public activateDataTable() {
    $(('#dataTableVisitors') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      responsive: true,
      'dom': 'lfrBtip',

      'buttons': [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'print',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Users',
      }
    });
    // const table = $('#datatable').DataTable();
  }
 public getGuests() {
    this.userService.getGuests().subscribe((response: IResponse) => {
        console.info('Guest ', response);
        this.guestList = response.data;
        this.guestList.forEach(guest => {
          guest.regTimeClean = moment(guest.reg_time).format('YYYY-MM-DD - hh:mm:ss - a');
        });
        setTimeout(() => {
          this.activateDataTable();
        }, 1000);
      },
      error => {
        this.notifyService.error('No guest found!', error);
      });
  }
}
