import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {isNullOrUndefined} from 'util';
import {CacheService} from '../cacheService/cache.service';
import { environment as env } from '../../../../environments/environment';

@Injectable()
export class EncryptDataService {
key: string;
  constructor(
    private cacheService: CacheService
  ) {  }
  encryptObject(data: any, key) {
    this.key = env.PRIVATE_KEY;
    // Encrypt the Password
    const object = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(object, this.key);
      this.cacheService.setSession(key, encrypted);
      return true;
  }
  encryptString(data: any, key) {
    this.key = env.PRIVATE_KEY;
    const encrypted = CryptoJS.AES.encrypt(data, this.key);
    this.cacheService.setSession(key, encrypted);
    return true;
  }
}


export class DecryptDataService {
key = env.PRIVATE_KEY;
  constructor(
  ) { }
  // Helps decrypt the user object
  decryptObject(key: string) {
    // console.log('KEY::', key);
    const code = sessionStorage.getItem(key);
    const userEncryption = code.toString();
    const decrypted = CryptoJS.AES.decrypt( userEncryption , this.key);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

  decryptString(key: string) {
    const code = sessionStorage.getItem(key);
    if (!isNullOrUndefined(code)) {
      const userEncryption = code.toString();
      const decrypted = CryptoJS.AES.decrypt( userEncryption , this.key);
      return decrypted.toString(CryptoJS.enc.Utf8);
    } else { return false; }

  }

}
