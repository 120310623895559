import {IRouting} from './shared/interfaces/irouting';
import {RouterModule, Routes} from '@angular/router';
import {AdminRoutesComponent} from './admin/admin-routes/admin-routes.component';
import {UserRoutesComponent} from './user/user-routes/user-routes.component';
import {LoginComponent} from './landing/login/login.component';
import {GuardService} from './shared/services/gaurdService/guard.service';
import {LoginOfficeComponent} from './landing/login-office/login-office.component';
import {SuperAdminRoutesComponent} from './super-admin/super-admin-routes/super-admin-routes.component';
import {UserDashboardComponent} from './user/user-dashboard/user-dashboard.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {ManageUsersComponent} from './admin/manage-users/manage-users.component';
import {TimeLogsComponent} from './admin/time-logs/time-logs.component';
import {VisitorsComponent} from './admin/visitors/visitors.component';
import {ManageCompaniesComponent} from './super-admin/manage-companies/manage-companies.component';
import {ManageDevicesComponent} from './super-admin/manage-devices/manage-devices.component';
import {SuperDashboardComponent} from './super-admin/super-dashboard/super-dashboard.component';
import {ManageDeviceAdminComponent} from './admin/manage-device-admin/manage-device-admin.component';
import {ViewChargesComponent} from './admin/view-charges/view-charges.component';
import {ReportsComponent} from './admin/reports/reports.component';


const landingRoutes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},

  // {path: 'administrator', component: LoginOfficeComponent},

  // {path: 'user', loadChildren: './shared/modules/user/user.module#UserModule'},

  {path: 'admin', loadChildren: './shared/modules/admin/admin.module#AdminModule'},

  // {path: 'super', loadChildren: './shared/modules/super-admin/super-admin.module#SuperAdminModule'},

  {path: '**', component: LoginComponent},
];

export const landingRouting: IRouting = {
  routes: RouterModule.forRoot(landingRoutes, {useHash: true}),
  components: [
    LoginComponent,
    LoginOfficeComponent,
    LoginOfficeComponent],
  entryComponent: [],
  providers: []
};


export const userRoutes: Routes = [
  {path: '', component: UserRoutesComponent, children: [
    /*{path: 'dashboard', component: UserDashboardComponent},
    {path: 'logs', component: TimeLogsComponent},
    {path: 'view-charge', component: ViewChargesComponent},*/

  ], canActivate: [GuardService]},
  {path: '**', component: UserRoutesComponent, canActivate: [GuardService]}
];

export const userRouting: IRouting = {
  routes: RouterModule.forChild(userRoutes),
  components: [
    UserRoutesComponent,
    UserDashboardComponent],
  entryComponent: [],
  providers: []
};

export const adminRoutes: Routes = [
  {path: '', component: AdminRoutesComponent, children: [
      {path: 'dashboard', component: AdminDashboardComponent},
    /*{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: 'dashboard', component: AdminDashboardComponent},
    {path: 'manage-users', component: ManageUsersComponent},
    {path: 'manage-logs', component: TimeLogsComponent},
    {path: 'view-charges', component: ViewChargesComponent},*/
    {path: 'reports', component: ReportsComponent},
      /*
    {path: 'visitors', component: VisitorsComponent},
    {path: 'manage-devices-admin', component: ManageDeviceAdminComponent}*/
  ], canActivate: [GuardService]},
  {path: '**', component: AdminRoutesComponent, canActivate: [GuardService] }
];

export const adminRouting: IRouting = {
  routes: RouterModule.forChild(adminRoutes),
  components: [
    AdminRoutesComponent,
    AdminDashboardComponent,
    ManageDeviceAdminComponent,
    ManageUsersComponent,
    ReportsComponent,
    VisitorsComponent],
    entryComponent: [],
    providers: []
};

export const superAdminRoutes: Routes = [
  {path: '', component: SuperAdminRoutesComponent, children: [
  /*  {path: 'dash-board', component: SuperDashboardComponent},
    {path: 'manage-companies', component: ManageCompaniesComponent},
    {path: 'manage-devices', component: ManageDevicesComponent}*/
  ],  canActivate: []},
  {path: '**', component: SuperAdminRoutesComponent,  canActivate: [GuardService] }
];

export const superAdminRouting: IRouting = {
  routes: RouterModule.forChild(superAdminRoutes),
  components: [
    SuperAdminRoutesComponent,
    ManageCompaniesComponent,
    SuperDashboardComponent,
    ManageDevicesComponent],
    entryComponent: [],
    providers: []
};

