import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import { environment as ENV } from '../../../environments/environment';
import {CSVService} from "../../shared/services/csvServices/pdf.service";
import {DecryptDataService} from "../../shared/services/encryption/encrypt-data.service";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
public usersList = [];
public guestList = [];
public logCount = 0;
public usersCount = 0;
public selectedFile: FileList;
public formData: any;
public downloading: boolean;


public guestCount = 0;
  constructor(private userService: UserService,
              private decryptService: DecryptDataService,
              private notifyService: NotificationService,
              private csvGen: CSVService) { }

  ngOnInit() {
  this.getUsersCount();
  this.getGuestsCount();
  this.getLogsCount();
  this.downloading  = false;
  }
  public getUsersCount() {
    this.userService.getUserCount().subscribe((response: IResponse) => {
        this.usersCount = response.data;
        console.info('Respomse 1', response);
      },
      () => {      });
  }
  public getLogsCount() {
    this.userService.getLogCount().subscribe((response: IResponse) => {
        this.logCount = response.data;
        console.info('Respomse 2', response);

      },
      () => {      });
  }
  public getGuestsCount() {
    this.userService.getGuestCount().subscribe((response: IResponse) => {
        this.guestCount = response.data;
        console.info('Respomse 4', response);

      },
      () => {});
  }
  public importFiles(e) {
    console.info('File selected ', e);
    this.fileUpload(e);
  }
  public importFileSelector(id) {
    $('#' + id).trigger('click');
  }
  public fileUpload(e) {
    this.selectedFile = e.target.files;
    if (this.selectedFile.length < 1) { return false; }
    console.log('Selected File: ', this.selectedFile);
    // let validExt;
    const file = this.selectedFile.item(0);
    let validExts;
    // '.xlsx', '.xls',
    validExts = ['.csv'];
    let fileExt;
    fileExt = file.name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.notifyService.error(`Invalid file selected, valid files are of " ${validExts.toString()}  types.`);
      return false;
    } else {
      this.notifyService.info('Importing in progress in background.');
      console.log('File selected is valid');
      this.formData = new FormData();
      this.formData.append('file', e.target.files[0]);
      this.proceedToImport(this.formData);
      console.log('FiLE:::', e.target.files);
      console.log('this is formdata:::::', this.formData);
    }
  }
  public proceedToImport(file) {
    const request = new XMLHttpRequest();
    const token = sessionStorage.getItem('access');
    let ENDPOINT = '';
    if (sessionStorage.getItem('system-target')) {
      const systemTarget = this.decryptService.decryptString('system-target');
      ENDPOINT = ENV.API_URL + '/' + ENV.API_VERSION + '/users/import?' + systemTarget;
    }
    request.open('POST',
      `${ENDPOINT}`, true);
    request.onload = (response) => {
      if (request.status === 200) {
        console.log('native request::', request.response);
        const response_ = JSON.parse(request.response);
        console.log('Invitation by bulk email sent.: ', response_);
        if (response_.code === 200 && response_.status === 'SUCCESS') {
          this.notifyService.success(response_.msg);
          this.getUsersCount();
          this.getGuestsCount();
          this.getLogsCount();
        } else {
          this.notifyService.error(response_.msg);
          this.getUsersCount();
          this.getGuestsCount();
          this.getLogsCount();
          return false;
        }
      } else {
        this.notifyService.error('Error while trying to import staffs.');
        console.log('request Failure::', request);
      }
    };
    request.setRequestHeader('Authorization', token);
    request.send(file);
  }
  public downloadFormat(url, id) {
    this.downloading = true;
    const hiddenIFrameID = id;
    let iframe;
    iframe = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
      iframe = document.createElement('iframe');
      iframe.id = hiddenIFrameID;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }
    iframe.src = url;
    setTimeout(() => {
      this.downloading = false;
    }, 2000);
  }
  public downloadCSV(target) {
    this.notifyService.info('Downloading in progress...');
    this.userService.exportToCSV(target).subscribe((response: IResponse) => {
      console.log('Response ', response);
      this.csvGen.downloadCsvFormatforStaffs(response.data, () => {
        this.notifyService.success('Your CSV have been downloaded.');
      });
      // this.notifyService.success('Download successful!');
    }, error => {
      this.notifyService.error('Unable to export to CSV');
      console.log('Errro ', error);
    });
    }
}
