import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as moment from 'moment';
declare const $: any;
@Component({
  selector: 'app-manage-devices',
  templateUrl: './manage-devices.component.html',
  styleUrls: ['./manage-devices.component.css']
})
export class ManageDevicesComponent implements OnInit {
  deviceList: any[];
  companyList: any[];
  savingDevice: boolean;
  selectedDevice: any;
  deviceData: any;
  constructor(private userService: UserService, private notifyService: NotificationService) { }

  ngOnInit() {
    this.savingDevice = false;
    this.selectedDevice = null;
    this.deviceList = [];
    this.companyList = [];
    this.deviceData = {
      dbId: null,
      mac_id: null,
      ime_id: null
    };
    this.getDevices();
    this.getCompanies();
  }
  public getCompanies() {
    this.userService.userListCompanies().subscribe((response: IResponse) => {
        console.info('Companies ', response);
        this.companyList = response.data;
      },
      error => {
        this.notifyService.error('No Company found!', error);
      });
  }
  public getDevices() {
    this.userService.adminListDevices().subscribe((response: IResponse) => {
        console.info('Devices ', response);
        this.deviceList = response.data;
        this.deviceList.forEach(device => {
          device.dateClean = moment(device.date).format('YYYY-MM-DD - hh:mm:ss - a');
        });
      },
      error => {
        this.notifyService.error('No Device found!', error);
      });
  }
  public createDeviceTrigger() {
    setTimeout(() => {
      $('#createDevice').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }

  public closeModal() {
    this.savingDevice = false;
    $('#createDevice').modal('hide');
    $('#createDevice').hide();
    this.deviceData = {
      dbId: null,
      mac_id: null,
      ime_id: null
    };
  }
  public validateAndCreate() {
    this.savingDevice = true;
    for ( const [key, value] of Object.entries(this.deviceData)) {
      if (!this.deviceData[key]) {
        this.savingDevice = false;
        this.notifyService.error(`${key} cannot be empty`);
        return false;
      }
    }
    console.info('Final ', this.deviceData);
    this.userService.createDevice(this.deviceData).subscribe((response: IResponse) => {
      console.info('Device Created ', response);
      this.notifyService.success(response.msg);
      this.getDevices();
      this.getCompanies();
      this.closeModal();
    }, error => {
      this.savingDevice = false;
      this.notifyService.error(error.msg || 'Cannot create device');
    });
  }
  public openDeviceLocation(device) {
    this.selectedDevice = device;
    setTimeout(() => {
      $('#viewDeviceLocation').modal({show: true, backdrop: 'static', keyboard: false });
    }, 200);

  }
  public closeDeviceLocation() {
    this.selectedDevice = null;
  }
}
