import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-super-admin-routes',
  templateUrl: './super-admin-routes.component.html',
  styleUrls: ['./super-admin-routes.component.css']
})
export class SuperAdminRoutesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
