import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as moment from 'moment';
declare const $: any;

@Component({
  selector: 'app-manage-device-admin',
  templateUrl: './manage-device-admin.component.html',
  styleUrls: ['./manage-device-admin.component.css']
})
export class ManageDeviceAdminComponent implements OnInit {
  deviceList: any[];
  companyList: any[];
  savingDevice: boolean;
  selectedDevice: any;
  deviceData: any;
  constructor(private userService: UserService, private notifyService: NotificationService) { }

  ngOnInit() {
    this.savingDevice = false;
    this.selectedDevice = null;
    this.deviceList = [];
    this.companyList = [];
    this.deviceData = {
      dbId: null,
      mac_id: null,
      ime_id: null
    };
    this.getDevices();
  }
  public getDevices() {
    this.userService.listDevices().subscribe((response: IResponse) => {
        console.info('Devices ', response);
        this.deviceList = response.data;
        this.deviceList.forEach(device => {
          device.dateClean = moment(device.date).format('YYYY-MM-DD - hh:mm:ss - a');
        });
      },
      error => {
        this.notifyService.error('No Device found!', error);
      });
  }
  public createDeviceTrigger() {
    setTimeout(() => {
      $('#createDevice').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }

  public closeModal() {
    this.savingDevice = false;
    $('#createDevice').modal('hide');
    $('#createDevice').hide();
  }
  public openDeviceLocation(device) {
    this.selectedDevice = device;
    setTimeout(() => {
      $('#viewDeviceLocation').modal({show: true, backdrop: 'static', keyboard: false });
    }, 200);

  }
  public closeDeviceLocation() {
    this.selectedDevice = null;
  }
}
