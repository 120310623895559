import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// declare const $: any;
import * as $ from 'jquery';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public user: any;

  static updateActiveBar(url) {
   setTimeout(() => {
     console.info('URL : ', url);
     let oldUrl;
     oldUrl = 'dashboard';
     $('.nav-item').removeClass('active');
     $(`#${url}`).addClass('active');
     if ($(`#${url}`).hasClass('active')) {
       oldUrl = url;
     } else {
       $(`#${oldUrl}`).addClass('active');
     }
   }, 500);
  }

  constructor(private route: Router) { }

  ngOnInit() {
    // console.info('vis ', routes[routes.length - 1]);
    this.user = jwt_decode(sessionStorage.getItem('access'));
    console.info('User ', this.user);
  }
  ngAfterViewInit() {
    const routes = this.route.url.split('/');
    SidebarComponent.updateActiveBar(routes[routes.length - 1]);
    console.info('New route');
    const element = document.querySelectorAll('.special-class');
    element.forEach((el) => {
      // console.info('Ele ', el);
    el.addEventListener('click', (e) => {
      $('.nav-item').removeClass('active');
      const id = el['id'];
      // console.info('ellll ', el['id']);
      if (id.includes('#') || !id) {return false; }
      $(`#${id}`).addClass('active');

    });
    });
  }
}
