import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {EncryptDataService} from '../../shared/services/encryption/encrypt-data.service';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';
import {UserService} from '../../shared/services/userService/user.service';
import {IResponse} from '../../shared/interfaces/iresponse';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as jwt_decode from 'jwt-decode';
import * as io from 'socket.io-client';
import * as $ from 'jquery';
// declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public credentials = {
    email: null,
    password: null,
    company: null,
    staff_id: null
  };
  socket: SocketIOClient.Socket;
  messages: any;
  loaders: boolean;
  errorMessage: string;
  companyList: any[];
  public EMAIL_VALIDATION =  ENV.EMAIL_VALIDATION;
  constructor(private userService: UserService,
              private notify: NotificationService,
              private encryptService: EncryptDataService,
              private navigatorService: NavigatorService, ) {
    this.loaders = false;
    this.companyList = [];
    this.getCompanies();
    /*this.socket = io.connect(ENV.SOCKET_URL, {query: {token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9uIjoiTEVWRUxfT05FIiwicm9sZSI6IkFETUlOIiwicGFzc3dvcmQiOm51bGwsImFjY2Vzc19zdGF0dXMiOiJBTExPV0VEIiwiX2lkIjoiNWJkZWVjNzY1YjI5NDkwNDUwOTA0YzdlIiwic3RhZmZfaWQiOiIyMzQiLCJmaXJzdF9uYW1lIjoiT2xhbGVrYW4iLCJsYXN0X25hbWUiOiJBcm9rb3l1IiwiZW1haWwiOiJhcm9rb3l1b2xhbGVrYW5AZ21haWwuY29tIiwiY3JlYXRlZEF0IjoiMjAxOC0xMS0wNFQxMjo1NjoyMy4zMjZaIiwidXBkYXRlZEF0IjoiMjAxOC0xMS0wNFQxMjo1NjoyMy4zMjZaIiwiX192IjowLCJzZWNyZXRUb2tlbiI6IkdlT09IaUhZfjdJeF9lN3pKVHhseiIsImFjY2Vzc0tleSI6IjEyMzg0LTA5ODc1cG9pdXl0eS05ODd0bzg5MHBvanJ0OS0yOTc2dHJlZDtzZGZndXl0cjA4ai05ODd0bzg5MHBvanJ0OS0ydHJlZDtzZGZndXl0cjA4ai05ODd0bzg5MHBvanJ0OS0ydHJlZDtzZGZndXl0cjA4ai1hcm9rb3l1b2xhbGVrYW4iLCJpYXQiOjE1NDIyNzc2OTcsImV4cCI6MTU0MjMyMDg5N30.rqXYoyMRWggS2RiIsyt_w2OSiL9SAVwRm8mAxHkkSl0', device_id: '',
      origin_selector_data_set: '', client_device_mac_address: '00000000-0656-db19-ffff-ffffc60120a2',
      client_device_special_pointer: 'ffffffff-c601-20a2-0000-00000018749e'}, transports: ['websocket']});*/
  }

  ngOnInit() {
    // this.socketIO();
    this.checkFullPageBackgroundImage();
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
  }
  public checkFullPageBackgroundImage() {
    const page = $('.full-page');
    const image_src = page.data('image');
    if (image_src !== undefined) {
      const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
      page.append(image_container);
    }
  }
  public loginProcess() {

    const user = JSON.parse(JSON.stringify(this.credentials));
    if (this.credentials.password && this.credentials.email && this.credentials.company) {
      this.retrieveCompanyByCode(this.credentials.company);
      this.loaders = true;
      if (this.credentials.email.match(this.EMAIL_VALIDATION)) {
       delete user.staff_id;
      } else {
        this.credentials.staff_id = user.email;
        delete this.credentials.email;
      }
      this.userService.auth(this.credentials).subscribe((response: IResponse) => {
        const decode =  jwt_decode(response.data);
        if (decode.role.toLowerCase() === 'admin') {
          this.navigatorService.navigateUrl(`/admin/dashboard`);
        } else {
          this.navigatorService.navigateUrl(`/user/logs`);
        }
      }, error => {
        this.loaders = false;
        console.info('Error after login ', error);
        this.notify.error(error.msg || 'Invalid login details!');
      });
    } else {
      this.notify.error('No Email or Username provided. also check company!');
    }
  }
  public getCompanies() {
    this.userService.getCompanies().subscribe((response: IResponse) => {
      console.info('Response after company ', response);
      response.data.forEach(data => {
        data.queryString = `origin_selector_data_set=${data.db_code}`;
      });
      this.companyList = response.data;
    }, error => {
      console.info('Error after company ', error.error.msg);
      this.notify.error(error.error.msg || 'Unable to get companies, please reload!');
    });
  }
  public retrieveCompanyByCode(code) {
    let dataFound = false;
    sessionStorage.removeItem('system-target');
    for (let i = 0; i < this.companyList.length; i++) {
      const company = this.companyList[i];
      if (company.company_code == code) {
        console.info('company.queryString ', company.queryString);
        this.encryptService.encryptString(company.queryString, 'system-target');
        dataFound = true;
        break;
      }
      if (this.companyList.length - 1 === i && dataFound === false) {
        this.credentials.company = null;
        this.notify.error('Company Code not recognised!');
      }
    }
  }
  public socketIO() {
    this.messages = [];

    this.socket.on('connected', (data: any) => {
      console.info('Connected  ', data);
    });
    this.socket.on('disconnect', (data: any) => {
      console.info('DisConnected  ', data);
    });
    this.socket.on('message-received', (msg: any) => {
      this.messages.push(msg);
      console.info(msg);
      console.info('messagessss => ', this.messages);
      this.socket.emit('sync/users', [{staff_id: '90209', first_name: 'Adeoye', last_name: 'Ojo'},
        {staff_id: '19701', first_name: 'Adolu', last_name: 'Abayomi', email: 'adm@upl.ng'}]);
    });
    this.socket.emit('deviceStatus', {
      msg: 'Client to server, can you hear me server?'
    });
    this.socket.on('sync/users', (data: any) => {
      console.info('Synch Users', data);
    });
    this.socket.on('sync/logs', (data: any) => {
      console.info('Synch Logs', data);
    });
    this.socket.on('clientError', (data: any) => {
      console.info('Error  ', data);
    });
    this.socket.on('clientRespond', (data: any) => {
      console.info('Respond  ', data);
    });

  }
  sendMessage() {
    const message = {
      text: 'Hello this is the world'
    };
    this.socket.emit('get/users', message);
    console.info(message);
  }
}

