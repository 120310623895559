import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
declare const $: any;
@Component({
  selector: 'app-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.css']
})
export class ManageCompaniesComponent implements OnInit {
  companyList: any[];
  deviceList: any[];
  showCompanyForm: boolean;
  savingCompany: boolean;
  loadingCompanies: boolean;
  companyData: any;
  constructor(private userService: UserService, private notifyService: NotificationService) {
    this.deviceList = [];
    this.getDevices();
  }

  ngOnInit() {
    this.savingCompany = false;
    this.loadingCompanies = true;
    this.companyList = [];
    this.companyData = {
      company_name: null,
      company_email: null,
      company_code: null
    };
    this.showCompanyForm = false;
  }
  public getCompanies() {
    this.userService.userListCompanies().subscribe((response: IResponse) => {
        console.info('Companies ', response);
        this.companyList = response.data;
        this.loadingCompanies = false;
        if (this.companyList.length > 0) {
          this.companyList.forEach((company) => {
            company.device = 0;
            console.info('Deice leng ', this.deviceList.length);
            this.deviceList.forEach((device) => {
              if (device.dbId._id === company._id) {
                company.device += 1;
              }
            });
          });
        }
      },
      error => {
        this.loadingCompanies = false;
        this.notifyService.error('No Company found!', error);
      });
  }
  public createCompanyTrigger() {
    setTimeout(() => {
      $('#createCompany').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }

public closeModal() {
  this.savingCompany = false;
  $('#createCompany').modal('hide');
    $('#createCompany').hide();
  this.companyData = {
    company_name: null,
    company_email: null,
    company_code: null
  };
}
public validateAndCreate() {
    this.savingCompany = true;
    for ( const [key, value] of Object.entries(this.companyData)) {
      if (!this.companyData[key]) {
        this.savingCompany = false;
        this.notifyService.error(`${key} cannot be empty`);
        return false;
      }
    }
    console.info('Final ', this.companyData);
    this.userService.createCompany(this.companyData).subscribe((response: IResponse) => {
      console.info('Comapn Created ', response);
      this.notifyService.success(response.msg);
      this.getCompanies();
      this.closeModal();
    }, error => {
      this.savingCompany = false;
      this.notifyService.error(error.msg || 'Cannot create company');
    });
}

  public getDevices() {
    this.userService.adminListDevices().subscribe((response: IResponse) => {
        console.info('Devices ', response);
        this.deviceList = response.data;
        this.getCompanies();
      },
      error => {
        this.getCompanies();
        this.notifyService.error('No Device found!', error);
      });
  }
}
